<template>
  <div class="col-12 mt-4 p-0 mb-3 ">
    <div class=" row col-12 ">
      <div class="col-12 col-xl-5 mb-3">
        <div class="input-group input-group-sm ">
          <div class="input-group-prepend input-group-sm">
            <select class="custom-select" v-model="mode">
              <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                {{ e.name }}
              </option>
            </select>
          </div>
          <input
            type="search"
            class="form-control form-control-sm"
            placeholder="ค้นหา / Seach"
            v-model="find"
            v-on:keyup.enter="Search()"
          />
          <div class="input-group-append">
            <button class="btn bt-main" @click="Search()">ค้นหา</button>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-4 mb-3">
        <b-form-file
          v-model="file1"
          :state="Boolean(file1)"
          size="sm"
          placeholder="เลือกไฟล์ที่ต้องการอัพโหลด"
          drop-placeholder="Drop file here..."
          @change="onChange"
        ></b-form-file>
      </div>
      <div class="col-12 col-xl-3 mb-3 text-right">
        <button
          type="button"
          class=" btn btn-sm bt-bnb"
          @click="UploadRemark()"
        >
          บันทึกข้อมูล
        </button>
        <button
          type="button"
          class="ml-2 btn-sm btn bt"
          @click="ClearTableRemark()"
        >
          ยกเลิก
        </button>
        <button type="button" class="ml-2 btn btn-sm bt-main">
          <a :href="file" download class="text-white"> ไฟล์ตัวอย่าง</a>
        </button>
      </div>
    </div>

    <div class="row mx-0 p-0 ">
      <div class="row col-12">
        <div class="col-12 col-xl-6 ">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
        </div>
      </div>

      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(MessageTH)="row">
            <p v-html="row.item.MessageTH"></p>
          </template>
          <template #cell(Type)="row">
            <span class="badge badge-twd" v-if="row.item.Type == 'shortdesc'">
              ShortDesc</span
            >
            <span class="badge badge-main" v-else>LongDesc</span>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { throws } from "assert";
import XLSX from "xlsx";
export default {
  name: "UploadProductRemark",
  // props: [],
  data() {
    return {
      file: require("@/files/RemarkMeassageExamp.xlsx"),
      file1: null,
      items: null,
      itemlist: null,
      totalRows: 0,
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      mode: "Web",
      select_mode: [
        { name: "ค้นหาด้วย Web", key: "Web" },
        { name: "ค้นหาด้วย Mode", key: "Mode" },
        { name: "ค้นหา Value", key: "Val" },
        { name: "ค้นหา Message", key: "MessageTH" },
        { name: "ค้นหา Except", key: "Except" },
      ],
      fields: [
        //  Mode	Val	Type	MessageTH	MessageEN	Web	StartDate	EndDate	Except
        { key: "Mode", label: "Mode", class: "text-left" },
        { key: "Val", label: "Value", class: "text-left" },
        { key: "Type", label: "Type", class: "text-left" },
        {
          key: "MessageTH",
          label: "MessageTH",
          class: "text-left",
          thStyle: { width: "20%" },
        },
        // { key: "MessageEN", label: "MessageEN", class: "text-left" },
        { key: "Web", label: "Web", class: "text-left" },
        { key: "StartDate", label: "StartDate", class: "text-left" },
        { key: "EndDate", label: "EndDate", class: "text-left" },
        { key: "Except", label: "Except", class: "text-left" },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  mounted() {},
  methods: {
    async onChange(event) {
      try {
        this.file = event.target.files ? event.target.files[0] : null;
        let fname = this.file.name;
        fname = fname.toLowerCase();

        if (fname.indexOf(".xls") < 0)
          throw `กรุณาตรวจสอบ format ไฟล์!!! file  ${fname}  is not excel file (.xls or .xlsx).`;

        if (this.file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            let rows = XLSX.utils.sheet_to_row_object_array(ws);

            var jsnRemarkMessages = rows.map((row) => ({
              Mode: String(row.Mode).trim(),
              Val: String(row.Val).trim(),
              Type: String(row.Type).trim(),
              MessageTH:
                row.MessageTH == undefined ? "" : String(row.MessageTH).trim(),
              MessageEN:
                row.MessageEN == undefined ? "" : String(row.MessageEN).trim(),
              Web: String(row.Web).trim(),
              StartDate: String(row.StartDate).trim(),
              EndDate: String(row.EndDate).trim(),
              Except: row.Except == undefined ? "" : String(row.Except).trim(),
            }));

            let findBlanks = jsnRemarkMessages.find(
              (itm) =>
                itm.Mode == "" ||
                itm.Mode == "undefined" ||
                itm.Val == "" ||
                itm.Val == "undefined" ||
                itm.Type == "" ||
                itm.Type == "undefined" ||
                itm.StartDate == "" ||
                itm.StartDate == "undefined" ||
                itm.EndDate == "" ||
                itm.EndDate == "undefined"
            );

            if (findBlanks) {
              this.$serviceMain.showErrorAlert(
                this,
                `กรุณาตรวจสอบไฟล์ Mode ,Val ,Type ,StartDate และ EndDate ไม่สามารถว่างได้`
              );
            }

            this.items = jsnRemarkMessages;
            this.itemlist = jsnRemarkMessages;
            this.totalRows = jsnRemarkMessages.length;
          };
          reader.readAsBinaryString(this.file);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async UploadRemark() {
      try {
        if (this.items == null) throw `กรุณาเลือกไฟล์ที่ต้องการอัพโหลด`;

        const data = { jsnRemarkMessages: this.items };

        var errdata = [];
        for (var i in data.jsnRemarkMessages) {
          if (
            new Date(data.jsnRemarkMessages[i].StartDate) >
            new Date(data.jsnRemarkMessages[i].EndDate)
          )
            errdata.push(data.jsnRemarkMessages[i].Val);
        }

        if (errdata.length > 0) {
          throw `กรุณาตรวจสอบ Value : ${errdata} StartDate ต้องน้อยกว่า EndDate`;
        }

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `remark/uploadpimproductremarkmessage`,
          data,
          1
        );
        this.$emit("getpimremark");
        this.$serviceMain.showSuccessAlert(
          this,
          "อัพโหลดข้อมูลสำเร็จ / Complete"
        );
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async ClearTableRemark() {
      this.file1 = null;
      this.items = null;
      this.itemlist = null;
      this.totalRows = 0;
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
  },
};
</script>

<style></style>
