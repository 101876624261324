<template>
  <div class="col-12 m-0 p-0 mb-3">
    <div class="row mx-0 px-0 ">
      <div class="col-12 col-xl-6 mb-3">
        <div class="input-group input-group-sm ">
          <div class="input-group-prepend input-group-sm">
            <select class="custom-select" v-model="mode">
              <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                {{ e.name }}
              </option>
            </select>
          </div>
          <input
            type="search"
            class="form-control form-control-sm"
            placeholder="ค้นหา / Seach"
            v-model="find"
            v-on:keyup.enter="Search()"
          />
          <div class="input-group-append">
            <button class="btn bt-main" @click="Search()">ค้นหา</button>
          </div>
        </div>
      </div>
      <div class="row col-12 mb-2 mx-0 px-0">
        <div class="col-12 col-xl-6 mt-2">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
          </p>
        </div>
        <div class="col-12 col-xl-6 mt-2">
          <div class="text-right">
            <button
              class="btn btn-sm bt-main mr-1"
              @click="add()"
              v-if="$roleaction('Insert Remarks')"
            >
              Add Remark
            </button>
            <button class="btn btn-sm bt-bnb" @click="export_excel">
              นำออกข้อมูล <span v-html="$svg_icon.excel"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(Active)="row">
            <span class="badge badge-bnb" v-if="row.item.Active == '1'">
              Yes</span
            >
            <span class="badge badge-twd" v-else>No</span>
          </template>
          <template #cell(MessageTH)="row">
            <p v-html="row.item.MessageTH"></p>
          </template>
          <template #cell(Type)="row">
            <span class="badge badge-twd" v-if="row.item.Type == 'shortdesc'">
              ShortDesc</span
            >
            <span class="badge badge-main" v-else>LongDesc</span>
          </template>
          <template #cell(action)="row">
            <span
              v-html="$svg_icon.edit"
              v-if="$roleaction('Update Remarks')"
              type="button"
              class="mr-1 text-muted"
              @click="edit(row.item)"
            ></span>
          </template>
          <template #cell(Error)="row">
            <span
              class="badge badge-twd font-0-9s"
              v-if="row.item.Error != '0'"
            >
              {{ row.item.Error }}</span
            >
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InProgress",
  data() {
    return {
      totalRows: 0,
      find: "",
      items: [],
      itemlist: [],
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      mode: "Mode",
      select_mode: [
        { name: "ค้นหาด้วย Mode", key: "Mode" },
        { name: "ค้นหาด้วย Value", key: "Val" },
        { name: "ค้นหา Type", key: "Type" },
        { name: "ค้นหา MessageTH", key: "MessageTH" },
      ],
      fields: [
        {
          key: "Mode",
          label: "Mode",
          class: "text-left",
          thStyle: { width: "5%" },
        },
        {
          key: "Val",
          label: "Value",
          class: "text-left",
          thStyle: { width: "5%" },
        },
        {
          key: "Type",
          label: "Type",
          class: "text-center",
          thStyle: { width: "10%" },
        },
        {
          key: "MessageTH",
          label: "MessageTH",
          class: "text-left",
          thStyle: { width: "20%" },
        },
        {
          key: "ShowOnWeb",
          label: "ShowOnWeb",
          class: "text-center",
          thStyle: { width: "10%" },
        },
        {
          key: "ShowDate",
          label: "ShowDate",
          class: "text-left",
          thStyle: { width: "15%" },
        },

        {
          key: "UpdateDate",
          label: "UpdateDate",
          class: "text-left",
          thStyle: { width: "10%" },
        },
        {
          key: "UpdateBy",
          label: "UpdateBy",
          class: "text-left",
          thStyle: { width: "10%" },
        },
        {
          key: "Active",
          label: "Active",
          class: "text-center",
          thStyle: { width: "5%" },
        },
        {
          key: "Error",
          label: "Error",
          thStyle: { width: "15%" },
        },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "10%" },
        },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
    GET_Error: function(v) {
      this.getdata();
    },
  },
  computed: {
    GET_Error: function() {
      return this.$store.getters["remarkMessageActions/GET_Error"];
    },
  },
  mounted() {
    this.getdata();
  },
  methods: {
    async export_excel() {
      let date = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}-${new Date().getTime()}`;
      let json = this.itemlist;
      let name = `ErrorRemark(${date}).xlsx`;
      this.$serviceMain.JsonToExcat(json, name);
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    check_action(data) {
      let { Active, Status } = data;
      if (
        Active == 1 &&
        Status !== "Expired" &&
        Status !== "Deleted" &&
        Status !== "Error"
      ) {
        return true;
      } else {
        return false;
      }
    },
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    add() {
      this.$router.push({
        name: "UpdateProductRemark",
        query: { action: "add" },
      });
    },
    edit(e) {
      this.$router.push({
        name: "UpdateProductRemark",
        query: { action: "edit", id: e.RemarkId },
      });
    },
    async getdata() {
      try {
        let getAPI = await this.$store.getters[
          "remarkMessageActions/GET_Error"
        ];
        if (getAPI !== null) {
          this.items = getAPI;
          this.itemlist = getAPI;
          this.totalRows = getAPI.length;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
